import { useContext, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { Grid, GridUnit } from '@hookdeck/theme';

import { EventStatus } from '../../../../../../../../typings/Event.interface';
import { Rule } from '../../../../../../../../typings/Ruleset.interface';
import { Webhook } from '../../../../../../../../typings/Webhook.interface';
import { RULE_ORDER, RULE_TYPES } from '../../../../../utils/rules';
import Button from '../../../../common/base/Button';
import { StyledCard } from '../../../../common/base/Card';
import Icon from '../../../../common/base/Icon';
import Text from '../../../../common/base/Text';
import Tooltip from '../../../../common/base/Tooltip';
import Dropdown from '../../../../common/Dropdown';
import { Div } from '../../../../common/helpers/StyledUtils';
import { useTruncatable } from '../../../../common/Truncatable';
import DestinationCard from '../DestinationCard';
import { group_by_options, ResourcesContext } from '../ResourcesContext';
import SourceCard from '../SourceCard';
import ConnectionPreview from './ConnectionPreview';
import DestinationPreview from './DestinationPreview';
import MetricsPreview from './MetricsPreview';
import SourcePreview from './SourcePreview';

const StyledLines = styled.div<{
  hover_index: number;
  index: number;
  selected: boolean;
  has_more: boolean;
  disabled?: boolean;
  group_by?: keyof typeof group_by_options;
}>(({ theme, hover_index, index, has_more, disabled, selected, group_by }) => {
  const hover_color = selected ? theme.colors.outline.primary : theme.colors.outline.hover.neutral;

  if (group_by === 'destination') {
    return css`
      height: 36px;
      display: flex;
      align-items: center;
      & > div:first-child {
        cursor: pointer;
        height: 0px;
        border-top: 3px solid
          ${hover_index === index ? hover_color : theme.colors.surface.base.variant_surface};
        width: 32px;
        position: relative;

        ::after {
          content: '';
          position: absolute;
          top: -5.5px;
          left: 0;
          height: 8px;
          border-radius: 50%;
          width: 8px;
          background-color: ${hover_index === index
            ? hover_color
            : theme.colors.surface.base.variant_surface};
        }
      }

      & > div:nth-child(2) {
        cursor: pointer;
        height: 100%;
        flex-grow: 1;
        display: flex;
        align-items: center;
        position: relative;
        z-index: 1;
        &::after {
          content: '';
          width: 100%;
          height: 0px;
          border-top: 3px solid
            ${hover_index === index ? hover_color : theme.colors.surface.base.variant_surface};
        }

        ${disabled &&
        hover_index !== index &&
        css`
          ${StyledConnectionBadge} {
            span {
              color: ${theme.colors.on.neutral.disabled};
            }
          }
        `}

        ${hover_index === index &&
        css`
          ${StyledConnectionBadge} {
            background-color: ${hover_color};
            span {
              color: ${theme.colors.on.neutral.primary_neutral};
            }
          }
        `}
      }

      & > div:nth-child(3) {
        position: relative;
        cursor: pointer;
        z-index: 0;

        & > div:first-child {
          position: relative;
          height: 0px;
          border-top: 3px solid
            ${hover_index === index ? hover_color : theme.colors.surface.base.variant_surface};
          width: 32px;
          ${index > 0 &&
          css`
            visibility: hidden;
          `}
          ${hover_index === index &&
          css`
            z-index: 2;
          `}
        }
        & > div:nth-child(2) {
          ${hover_index > index &&
          css`
            z-index: 2;
          `}
          position: absolute;
          left: 3px;
          top: 0;
          height: 42px;
          width: 30px;
          border-top-left-radius: 8px;
          border-top: 3px solid
            ${hover_index > index ? hover_color : theme.colors.surface.base.variant_surface};
          border-left: 3px solid
            ${hover_index > index ? hover_color : theme.colors.surface.base.variant_surface};
          ${!has_more &&
          css`
            visibility: hidden;
          `}
          ${index > 0 &&
          css`
            ${hover_index === index &&
            css`
              z-index: 2;
            `}
            left: 0;
            visibility: visible;
            border-top: 3px solid
              ${hover_index === index ? hover_color : theme.colors.surface.base.variant_surface};
            border-left: 3px solid
              ${hover_index === index ? hover_color : theme.colors.surface.base.variant_surface};
            height: 40px;
            margin-left: 6px;
            top: unset;
            transform: rotate(180deg) translateX(100%) translateY(100%);
          `}
        }
        & > div:nth-child(3) {
          cursor: pointer;
          ${hover_index > index &&
          hover_index !== index &&
          css`
            z-index: 2;
          `}
          ${(!has_more || index === 0) &&
          css`
            visibility: hidden;
          `}
        position: absolute;
          height: 80px;
          left: 3px;
          top: 0;
          border-left: 3px solid
            ${hover_index > index ? hover_color : theme.colors.surface.base.variant_surface};
          transform: translateY(-50%);
        }
      }
    `;
  }

  return css`
    height: 36px;
    display: flex;
    align-items: center;
    & > div:first-child {
      position: relative;
      cursor: pointer;
      height: 0px;
      border-top: 3px solid
        ${hover_index === index ? hover_color : theme.colors.surface.base.variant_surface};
      width: 32px;
      ${index > 0 &&
      css`
        visibility: hidden;
      `}
      ${hover_index === index &&
      css`
        z-index: 2;
      `}

      ::after {
        content: '';
        position: absolute;
        top: -5.5px;
        left: 0;
        height: 8px;
        border-radius: 50%;
        width: 8px;
        background-color: ${hover_index >= 0
          ? hover_color
          : theme.colors.surface.base.variant_surface};
      }
    }
    & > div:nth-child(2) {
      position: relative;
      cursor: pointer;
      z-index: 0;
      & > div:first-child {
        height: 0px;
        border-top: 3px solid
          ${hover_index === index ? hover_color : theme.colors.surface.base.variant_surface};
        width: 32px;
        ${index > 0 &&
        css`
          visibility: hidden;
        `}
      }
      & > div:nth-child(2) {
        ${hover_index > index &&
        css`
          z-index: 2;
        `}
        position: absolute;
        left: -31px;
        top: 0;
        height: 42px;
        width: 30px;
        border-top-right-radius: 8px;
        border-top: 3px solid
          ${hover_index > index ? hover_color : theme.colors.surface.base.variant_surface};
        border-right: 3px solid
          ${hover_index > index ? hover_color : theme.colors.surface.base.variant_surface};
        ${!has_more &&
        css`
          visibility: hidden;
        `}
        ${index > 0 &&
        css`
          ${hover_index === index &&
          css`
            z-index: 2;
          `}
          visibility: visible;
          border-top: 3px solid
            ${hover_index === index ? hover_color : theme.colors.surface.base.variant_surface};
          border-right: 3px solid
            ${hover_index === index ? hover_color : theme.colors.surface.base.variant_surface};
          height: 40px;
          margin-left: -3px;
          top: unset;
          transform: rotate(180deg) translateX(-100%) translateY(100%);
        `}
      }
      & > div:nth-child(3) {
        cursor: pointer;
        ${hover_index > index &&
        hover_index !== index &&
        css`
          z-index: 2;
        `}
        ${(!has_more || index === 0) &&
        css`
          display: none;
        `}
        position: absolute;
        height: 80px;
        left: -1px;
        top: 0;
        border-left: 3px solid
          ${hover_index > index ? hover_color : theme.colors.surface.base.variant_surface};
        transform: translateY(-50%);
      }
    }

    & > div:nth-child(3) {
      cursor: pointer;
      height: 100%;
      flex-grow: 1;
      display: flex;
      align-items: center;
      position: relative;
      z-index: 1;
      &::after {
        content: '';
        width: 100%;
        height: 0px;
        border-top: 3px solid
          ${hover_index === index ? hover_color : theme.colors.surface.base.variant_surface};
      }

      ${disabled &&
      hover_index !== index &&
      css`
        ${StyledConnectionBadge} {
          span {
            color: ${theme.colors.on.neutral.disabled};
          }
        }
      `}

      ${hover_index === index &&
      css`
        ${StyledConnectionBadge} {
          background-color: ${hover_color};
          span {
            color: ${theme.colors.on.neutral.primary_neutral};
          }
        }
      `}
    }
  `;
});

const StyledRuleIcon = styled(Icon)(
  ({ theme }) => css`
    padding: ${theme.spacing(1)} ${theme.spacing(0.5)};
    z-index: 1;
    fill: ${theme.colors.on.neutral.primary_neutral};
    color: ${theme.colors.on.neutral.primary_neutral};
  `,
);

const StyledArrow = styled.svg<{ highlighted: boolean; selected: boolean }>(
  ({ theme, highlighted, selected }) => css`
    fill: ${theme.colors.surface.base.variant_surface};
    position: absolute;
    left: -7px;
    top: 13px;
    ${highlighted &&
    css`
      fill: ${theme.colors.outline.hover.neutral};
    `};
    ${selected &&
    css`
      fill: ${theme.colors.outline.primary};
    `};
  `,
);

const StyledConnectionBadge = styled(Div)<{ warning?: true }>(
  ({ theme, warning }) => css`
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 25px;
    background-color: ${warning
      ? theme.colors.surface.base.warning
      : theme.colors.surface.base.variant_surface};

    span {
      color: ${warning ? theme.colors.on.hue.warning : theme.colors.on.neutral.primary_neutral};
    }
  `,
);

const StyledConnectionWrapper = styled.div(
  ({ theme }) => css`
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    left: -${theme.spacing(2)};
    right: ${theme.spacing(4)};
  `,
);

const StyledCardWrapper = styled.div<{
  focused: boolean;
  highlighted: boolean;
  selected: boolean;
  disabled: boolean;
}>(({ focused, theme, highlighted, selected, disabled }) => {
  const hover_color = selected ? theme.colors.outline.primary : theme.colors.outline.hover.neutral;

  const border = css`
    pointer-events: none;
    content: '';
    position: absolute;
    border-radius: ${theme.radius.normal};
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
  `;
  return css`
    position: relative;

    ${StyledCard} {
      box-shadow: ${theme.elevation[1]};

      button:hover {
        background-color: ${theme.colors.surface.base.hover.neutral};
      }

      ${disabled &&
      css`
        background-color: ${theme.colors.surface.base.disabled};
      `}

      ${focused
        ? css`
            &::after {
              ${border}
              border: 3px solid ${theme.colors.outline.primary};
            }
          `
        : css`
            &:not(:hover) {
              &::after {
                ${border}
                border: 3px solid ${hover_color};
                opacity: 0;
                ${highlighted &&
                css`
                  opacity: 1;
                `}
              }
            }
          `}
    }
  `;
});

const ConnectionName: React.FC<{ name: string }> = ({ name }) => {
  const ref = useRef<React.ElementRef<'div'>>(null);

  const [display_name, truncated] = useTruncatable({
    text: name,
    target: ref.current,
  });

  return (
    <Div ref={ref} flex w={100}>
      <StyledConnectionBadge
        p={{ x: 2, y: 1 }}
        style={{
          maxWidth: `100%`,
          position: 'relative',
        }}>
        <Text monospace ellipsis as={'span'} width={100} aria-label={name}>
          {truncated ? (
            <Tooltip tooltip={name} placement="bottom-start">
              {display_name}
            </Tooltip>
          ) : (
            display_name
          )}
        </Text>
      </StyledConnectionBadge>
    </Div>
  );
};

type Props = {
  connections: Webhook[];
  timeseries_by_connection_id: {
    '2h': Record<string, [[Date, Date], Record<EventStatus, number>][]>;
    '4h': Record<string, [[Date, Date], Record<EventStatus, number>][]>;
  };
};

const StyledQuickWrapper = styled.div<{ side: 'left' | 'right' }>(
  ({ side }) => css`
    height: 32px;
    position: absolute;
    top: -30px;
    z-index: 1;
    ${side === 'right'
      ? css`
          padding-left: 46px;
          right: -46px;
        `
      : css`
          padding-right: 42px;
          left: -42px;
        `}
  `,
);
const StyledQuickAdd = styled(Button)(
  ({ theme }) => css`
    a {
      height: 28px;
      width: 28px;
      background-color: ${theme.colors.surface.base.background};
      border: 3px dashed ${theme.colors.outline.neutral};
      border-radius: 50%;

      &:hover {
        background-color: ${theme.colors.surface.container.primary};
        border-color: ${theme.colors.on.neutral.primary};
        color: ${theme.colors.on.neutral.primary};
      }
    }
  `,
);

const GroupedConnections: React.FC<Props> = ({ connections, timeseries_by_connection_id }) => {
  const [hovered, setHovered] = useState<{
    id?: string;
    selected?: boolean;
    source_id?: string;
    destination_id?: string;
  } | null>(null);
  const { group_by } = useContext(ResourcesContext);

  const current_index = hovered && connections.findIndex(({ id }) => id === hovered.id);

  const getHoverProps = (connection: Webhook) => ({
    onMouseEnter: () => setHovered({ id: connection.id, selected: false }),
    onMouseLeave: () => setHovered(null),
  });

  const hovered_connection = hovered && connections.find(({ id }) => id === hovered.id);

  return (
    <Grid>
      <GridUnit size={1 / 4}>
        {(group_by === 'source' ? [connections[0]] : connections).map((connection) => (
          <Div
            m={{ b: 3 }}
            key={connection.id}
            onMouseEnter={() => setHovered({ source_id: connection.source.id })}
            onMouseLeave={() => setHovered(null)}>
            <Dropdown
              placement="bottom-start"
              parent_width={{ min: 340 }}
              onToggle={() => {
                setHovered(null);
              }}
              renderToggle={(opened, toggle) => (
                <StyledCardWrapper
                  focused={opened}
                  disabled={!!connection.source.disabled_at}
                  highlighted={
                    !!(group_by === 'source'
                      ? !!hovered_connection &&
                        hovered_connection.source.id === connection.source.id
                      : hovered && connection.id === hovered.id)
                  }
                  selected={
                    !!(group_by === 'source'
                      ? hovered?.selected && hovered.source_id === connection.source.id
                      : hovered && connection.id === hovered.id && hovered.selected)
                  }>
                  <SourceCard source={connection.source} onClick={() => toggle(!opened)} />
                </StyledCardWrapper>
              )}>
              <SourcePreview source={connection.source} />
            </Dropdown>
            <Div style={{ position: 'relative' }}>
              <StyledQuickWrapper side="right">
                {group_by === 'source' && hovered?.source_id === connection.source.id && (
                  <StyledQuickAdd
                    minimal
                    icon="add_circle"
                    to={`/connections/new?source_id=${connection.source.id}`}
                  />
                )}
              </StyledQuickWrapper>
            </Div>
          </Div>
        ))}
      </GridUnit>
      <GridUnit size={3 / 4}>
        {connections.map((connection, i) => (
          <Div flex key={connection.id}>
            <Div style={{ flexGrow: 1 }}>
              <Grid>
                <GridUnit size={3 / 5}>
                  <Dropdown
                    placement="bottom-start"
                    parent_width={{ min: 320 }}
                    onToggle={(opened) =>
                      setHovered(opened ? { id: connection.id, selected: true } : null)
                    }
                    renderToggle={(opened, toggle) => (
                      <StyledLines
                        disabled={!!connection.disabled_at}
                        hover_index={opened ? i : current_index ?? -1}
                        selected={!!((opened && hovered?.selected) || hovered?.selected)}
                        index={i}
                        group_by={group_by}
                        has_more={connections.length - 1 > i}
                        onClick={() => {
                          toggle(!opened);
                        }}
                        {...(!opened ? getHoverProps(connection) : {})}>
                        <div />
                        {group_by === 'source' && (
                          <div>
                            <div />
                            <div />
                            <div />
                          </div>
                        )}
                        <div>
                          <StyledConnectionWrapper>
                            <Div
                              flex
                              style={{
                                width: `calc(100% - 32px - ${
                                  connection.paused_at || connection.disabled_at ? '40px' : '0px'
                                } - ${(connection.rules.length ?? 0) * 20}px)`,
                              }}>
                              {connection.name && <ConnectionName name={connection.name} />}
                            </Div>
                            <Div flex={{ gap: 2 }} m={group_by === 'source' ? { r: 1 } : {}}>
                              {connection.paused_at && (
                                <StyledConnectionBadge p={1.5} warning>
                                  <Icon icon="pause_circle" />
                                </StyledConnectionBadge>
                              )}
                              {connection.disabled_at && (
                                <StyledConnectionBadge p={1.5}>
                                  <Icon icon="disable" />
                                </StyledConnectionBadge>
                              )}
                              {connection.rules.length > 0 && (
                                <StyledConnectionBadge p={1.5} flex={{ gap: 1 }}>
                                  {connection.rules
                                    .reduce((rules, rule) => {
                                      if (!rules.some(({ type }) => rule.type === type)) {
                                        rules.push(rule);
                                      }
                                      return rules;
                                    }, [] as Rule[])
                                    .sort((a, b) => RULE_ORDER[a.type] - RULE_ORDER[b.type])
                                    .map((rule) => (
                                      <StyledRuleIcon
                                        key={rule.type}
                                        icon={RULE_TYPES[rule.type].icon}
                                        small
                                      />
                                    ))}
                                </StyledConnectionBadge>
                              )}
                            </Div>
                          </StyledConnectionWrapper>
                        </div>
                        {group_by === 'destination' && (
                          <div>
                            <div />
                            <div />
                            <div />
                          </div>
                        )}
                      </StyledLines>
                    )}>
                    <ConnectionPreview connection={connection} />
                  </Dropdown>
                </GridUnit>
                <GridUnit size={2 / 5}>
                  {(group_by !== 'destination' || i === 0) && (
                    <Div
                      onMouseEnter={() => setHovered({ destination_id: connection.destination.id })}
                      onMouseLeave={() => setHovered(null)}>
                      <Dropdown
                        parent_width={{ min: 340 }}
                        placement="bottom-end"
                        renderToggle={(opened, toggle) => (
                          <Div style={{ position: 'relative' }}>
                            <StyledArrow
                              highlighted={
                                !!(group_by === 'destination'
                                  ? !!hovered_connection &&
                                    hovered_connection.destination.id === connection.destination.id
                                  : hovered && connection.id === hovered.id)
                              }
                              selected={
                                !!(group_by === 'destination'
                                  ? hovered?.selected
                                  : hovered && connection.id === hovered.id && hovered.selected)
                              }
                              width="8"
                              height="10"
                              viewBox="0 0 8 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path d="M7.5 4.13397C8.16667 4.51887 8.16667 5.48113 7.5 5.86603L1.5 9.33013C0.833334 9.71503 -4.47338e-07 9.2339 -4.13689e-07 8.4641L-1.10848e-07 1.5359C-7.71986e-08 0.766098 0.833333 0.284973 1.5 0.669873L7.5 4.13397Z" />
                            </StyledArrow>
                            <StyledCardWrapper
                              focused={opened}
                              disabled={!!connection.destination.disabled_at}
                              highlighted={
                                !!(group_by === 'destination'
                                  ? !!hovered_connection &&
                                    hovered_connection.destination.id === connection.destination.id
                                  : hovered && connection.id === hovered.id)
                              }
                              selected={
                                !!(group_by === 'destination'
                                  ? hovered?.selected
                                  : hovered && connection.id === hovered.id && hovered.selected)
                              }>
                              <DestinationCard
                                destination={connection.destination}
                                onClick={() => toggle(!opened)}
                              />
                            </StyledCardWrapper>
                          </Div>
                        )}>
                        <DestinationPreview destination={connection.destination} />
                      </Dropdown>
                      <Div style={{ position: 'relative' }}>
                        <StyledQuickWrapper side="left">
                          {group_by === 'destination' &&
                            hovered?.destination_id === connection.destination.id && (
                              <StyledQuickAdd
                                minimal
                                icon="add_circle"
                                to={`/connections/new?destination_id=${connection.destination.id}`}
                              />
                            )}
                        </StyledQuickWrapper>
                      </Div>
                    </Div>
                  )}
                </GridUnit>
              </Grid>
              <Div key={connection.id} p={{ b: 2.5 }} />
            </Div>
            <Div flex>
              <Div m={{ l: 4, r: 2 }}>
                <MetricsPreview
                  data_2h_sample={timeseries_by_connection_id['2h'][connection.id] || []}
                  data_4h_sample={timeseries_by_connection_id['4h'][connection.id] || []}
                  connection={connection}
                />
              </Div>
              <Div flex {...getHoverProps(connection)}>
                <Button minimal icon="link" to={`/connections/${connection.id}`} />
              </Div>
            </Div>
          </Div>
        ))}
      </GridUnit>
    </Grid>
  );
};

export default GroupedConnections;
