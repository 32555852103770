import { format, getDay } from 'date-fns';
import React, { useMemo, useState } from 'react';
import NumberFormat from 'react-number-format';
import { usePopper } from 'react-popper';
import styled, { css, keyframes } from 'styled-components';

import { StyledCard, StyledCardSection } from '../base/Card';
import Text from '../base/Text';
import { Div } from '../helpers/StyledUtils';

const StyledChartContainer = styled.div<{ size: 'small' | 'medium' }>(
  ({ theme, size }) => css`
    height: ${size === 'small' ? '20px' : '36px'};
    display: flex;
    align-items: flex-end;
    width: 100%;
    margin-bottom: ${theme.spacing(0)};
  `,
);

const StyledChartEntry = styled.div<{
  low_opacity?: boolean;
  hovered?: boolean;
  within_range?: boolean;
}>(
  ({ low_opacity, hovered }) => css`
    display: flex;
    flex-direction: column-reverse;
    position: relative;
    z-index: 1;
    padding: 0 2px;
    height: 100%;
    flex-grow: 1;

    ${low_opacity &&
    !hovered &&
    css`
      ${StyledChartValue} {
        opacity: ${low_opacity ? 0.5 : 1};
        transition: opacity 0.15s ease-in;
      }
    `}
    ${hovered &&
    css`
      z-index: 10;
    `}
  `,
);

const pulse_keyframe = keyframes`
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.8;
  }
`;

const StyledChartValue = styled.div<{
  theme_color?: string;
  $loading?: boolean;
  max_value: number;
  value: number;
}>(
  ({ theme, max_value, value, $loading, theme_color }) => css`
    height: ${((value / max_value) * 100).toFixed(1)}%;
    min-height: 4px;
    background-color: ${value === 0 || !theme_color
      ? theme.colors.surface.base.variant_surface
      : (theme.colors.surface.base[theme_color] as any)};
    border-radius: 2px;

    min-width: 8px;
    width: 100%;
    margin: 1px 0px;
    align-self: end;

    ${$loading &&
    css`
      background-color: ${theme.colors.surface.base.variant_surface};
      animation: ${pulse_keyframe} 1.5s ease-in-out 0.5s infinite;
    `}
  `,
);

const StyledChartTooltipWrapper = styled.div`
  width: fit-content;
  z-index: 2000;
`;

const StyledChartTooltip = styled(StyledCard)(
  ({ theme }) => css`
    box-shadow: ${theme.elevation[2]};
    margin-top: 8px;
  `,
);

const granularity_formating = {
  second: 'MMM d, h:mm:ss a',
  minute: 'MMM d, h:mm a',
  hour: 'MMM d, h:mm a',
  day: 'MMM d, h:mm a',
};

const getTooltipTimeLabel = (
  start: Date,
  end: Date,
  resolution: keyof typeof granularity_formating,
) => {
  const format_string = granularity_formating[resolution];
  const start_label = format(start, format_string);
  const end_label = format(
    end,
    getDay(start) === getDay(end) ? format_string.replace('MMM d,', '') : format_string,
  );

  return `${start_label} - ${end_label}`;
};

const PreviewHistogram: React.FC<{
  values: [[Date, Date], Record<string, number>][];
  resolution: keyof typeof granularity_formating;
  dimentions_config: { [key: string]: { color: string; label: string } };
  show_tooltip?: boolean;
  size?: 'small' | 'medium';
}> = ({ values, resolution, show_tooltip, dimentions_config, size }) => {
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const element = document.querySelector('#main');
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'preventOverflow',
        options: {
          boundary: element as any,
          mainAxis: true,
          altAxis: false,
          padding: 8,
        },
      },
    ],
  });

  const placeholder_data = useMemo(
    () => Array.from(Array(size === 'small' ? 6 : 14).keys()).map(() => Math.random() * 100),
    [size],
  );

  const [hovered_date, setHoveredDate] = useState<{ start_date: Date; end_date: Date } | null>(
    null,
  );

  const max_value =
    values &&
    Math.max(...values.map(([, object]) => Object.values(object).reduce((a, b) => a + b, 0)));

  const dimention_keys = Object.keys(dimentions_config);

  return (
    <StyledChartContainer size={size || 'medium'}>
      {!values ||
        (values.length === 0 &&
          placeholder_data.map((v, i) => (
            <StyledChartEntry key={i}>
              <StyledChartValue $loading max_value={100} value={v} />
            </StyledChartEntry>
          )))}
      {values?.map(([[start_date, end_date], object]) => {
        const hovered = hovered_date
          ? hovered_date.start_date.getTime() === start_date.getTime()
          : undefined;
        return (
          <StyledChartEntry
            hovered={hovered || undefined}
            key={start_date.toISOString()}
            low_opacity={(hovered_date && !hovered) || undefined}
            onMouseEnter={() => show_tooltip && setHoveredDate({ start_date, end_date })}
            onMouseLeave={() => show_tooltip && setHoveredDate(null)}>
            {hovered && <div ref={setReferenceElement} />}
            {Object.entries(object)
              .sort(
                ([dimention_a], [dimention_b]) =>
                  dimention_keys.indexOf(dimention_a) - dimention_keys.indexOf(dimention_b),
              )
              .filter(([, count]) => count > 0)
              .map(([dimention, count]) =>
                count ? (
                  <StyledChartValue
                    key={dimention}
                    theme_color={dimentions_config[dimention].color}
                    max_value={max_value}
                    value={count}
                  />
                ) : null,
              )}
            {!Object.values(object).some((v) => v > 0) && (
              <StyledChartValue max_value={max_value} value={0} />
            )}
          </StyledChartEntry>
        );
      })}
      {show_tooltip && hovered_date && (
        <StyledChartTooltipWrapper
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}>
          <StyledChartTooltip overflow_hidden>
            <StyledCardSection p={{ x: 3, y: 2 }}>
              <Text heading text_wrap={false} as="span">
                {getTooltipTimeLabel(hovered_date.end_date, hovered_date.start_date, resolution)}
              </Text>
            </StyledCardSection>
            <StyledCardSection p={{ x: 3, y: 2 }}>
              {Object.entries(
                values.find(
                  ([[start_date]]) =>
                    start_date.toISOString() === hovered_date.start_date.toISOString(),
                )?.[1] || {},
              )
                .sort(
                  ([dimention_a], [dimention_b]) =>
                    dimention_keys.indexOf(dimention_a) - dimention_keys.indexOf(dimention_b),
                )
                .map(([dimention, count], i) => (
                  <Div
                    key={dimention}
                    m={{ t: i === 0 ? 0 : 1 }}
                    flex={{ justify: 'space-between' }}>
                    <Text subtitle>{dimentions_config[dimention].label}</Text>
                    <Text subtitle m={{ l: 4 }}>
                      <NumberFormat
                        renderText={(v) => v}
                        displayType="text"
                        value={count}
                        thousandSeparator={','}
                      />
                    </Text>
                  </Div>
                ))}
              {Object.entries(
                values.find(
                  ([[start_date]]) =>
                    start_date.toISOString() === hovered_date.start_date.toISOString(),
                )?.[1] || {},
              ).length === 0 && <Text muted>No data for that period.</Text>}
            </StyledCardSection>
          </StyledChartTooltip>
        </StyledChartTooltipWrapper>
      )}
    </StyledChartContainer>
  );
};

export default PreviewHistogram;
